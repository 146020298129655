import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import TopMenu from "../components/topMenu"
import Footer from "../components/footer"
import PhotoGallery from "../components/photoGallery"
import ReactMarkdown from "react-markdown"

const PostPageTemplate = (props) => {
    // console.log("PostPageTemplate: ", props);
    // const { previous, next } = props.pageContext;
    // const slug = props.data.mdx.slug;
    const fileNameRegExp = /\/([^/]+)\.\w{1,4}$/;
    const post = props.data.mdx;
    const slug = post.slug.split('.')[0];
    const settings = props.data.settings.childMdx.frontmatter;
    const cloudinaryImages = props.data.cloudinaryImages.nodes;

    let pictures = post.frontmatter.pictures ? post.frontmatter.pictures.map((pic, index) => {
      const res = fileNameRegExp.exec(pic.image);
      if (!res || !res[1]) return undefined;
      const picData = cloudinaryImages.filter(pic => pic.public_id.includes(res[1]))[0];
      return {
        index: index,
        type: picData.type || "image",
        id: picData.id,
        src: picData.imgUrl,
        thumbnail: picData.thumb,
        w: picData.width,
        h: picData.height,
        title: pic.title || "",
        description: picData.description || "",
      };
    }) : [];

    // console.warn(pictures);

    // pictures = [
    //   {
    //     type: "text",
    //     title: post.frontmatter.title,
    //     description: post.frontmatter.description,
    //   }, 
    //   ...pictures
    // ];
    // console.log(pictures);

    // const columns = chunkifyArray(pictures, 3, true);

    const getThumbnailContent = (item) => {
      return (
        <div className="">
          <img src={item.thumbnail} alt={item.title} loading="lazy" className="gallery-item__pic gallery-item__pic--h330 mb1"/>
          <h5 className="mb0">{item.title}</h5>
        </div>
      );
    }

    return (
      <section className={"page post lang-" + props.pageContext.language}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description}
        />
        <div className="page__in container-1200">
        <TopMenu smallLogo={true} lang={props.pageContext.language} languages={props.pageContext.languages} translation={props.pageContext.translation} settings={settings} slug={slug}></TopMenu>
          <div className="mb150">
          <div className="post-header">
            <div className="columns">
              {/* <div className="column-40 au-post-image" style={{backgroundImage: "url("+post.frontmatter.image+")"}}>
              </div> */}
              <div className="column-40 au-post-image" >
                <img src={post.frontmatter.image} alt="" data-sal="slide-up" data-sal-duration="1000"/>
              </div>
              <div className="column-60 au-post-title">
                <div className="au-post-title__in">
                  <div className="text-small color-gold mb1">{post.frontmatter.date}</div>
                  <h3 className="post-title fwb">{post.frontmatter.title}</h3>
                </div>
              </div>
            </div>
              {
                post.frontmatter.content && <div className="au-post-desc">
                  <div className="au-post-desc__in">
                    <ReactMarkdown source={post.frontmatter.content} linkTarget="_blank" className="text"/>
                  </div>
                </div>
              }
          </div> 
          {
            Boolean(pictures.length) && <PhotoGallery items={pictures} options={{}} thumbnailContent={getThumbnailContent} className="post-gallery"/>
          } 
          </div>
        </div>
        <Footer lang={props.pageContext.language} languages={props.pageContext.languages} translation={props.pageContext.translation}></Footer>
      </section>
    )
  }

export default PostPageTemplate

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    mdx( id: { eq: $id } ) {
      id
      slug
      frontmatter {
        image
        date(formatString: "DD MMMM YYYY")
        content
        title
        pictures {
          image
          title
        }
      }
    }
    cloudinaryImages: allCloudinaryItem {
      nodes {
        id
        public_id
        thumb
        imgUrl
        width
        height
        orientation
      }
    }
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          detectLanguage
          address
          address2
          phone
          instagram
          facebook
          pinterest
          googlemaplink
          email
        }
      }
    }
  }
`
